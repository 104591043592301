import React from "react";
import '../Styles/style_aboutscreen.css';

const AboutScreen = () => {


    return (
        <>
            <section className="about-container">
                <h1 id="wapa-title">About WAPA</h1>
            </section>
            <section className="info-container">
                
            </section>
        </>
    )
}
export default AboutScreen;